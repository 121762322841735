import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';


const messages = defineMessages({

	sort_published_down: {
		id: 'sort_published_down',
		defaultMessage: 'Published (Newest to Oldest)'
	},
	sort_published_up: {
		id: 'sort_published_up',
		defaultMessage: 'Published (Oldest to Newest)'
	},
	sort_updated_down: {
		id: 'sort_updated_down',
		defaultMessage: 'Updated (Newest to Oldest)'
	},
	sort_updated_up: {
		id: 'sort_updated_up',
		defaultMessage: 'Updated (Oldest to Newest)'
	},
	sort_skill_down: {
		id: 'sort_skill_down',
		defaultMessage: 'Skill Level (Easy to Hard)'
	},
	sort_skill_up: {
		id: 'sort_skill_up',
		defaultMessage: 'SkillLevel (Hard to Easy)'
	},
	sort_title_down: {
		id: 'sort_title_down',
		defaultMessage: 'Title (A-Z)'
	},
	sort_title_up: {
		id: 'sort_title_up',
		defaultMessage: 'Title (Z-A)'
	}


})


class SortLessons extends Component {

    selectSort = (e) => {
        this.props.selectSort(e.target.value);
	}
		

    render() {
       
        const { formatMessage } = this.props.intl;

        let sortOptionsLessons = [formatMessage(messages.sort_published_down),
            formatMessage(messages.sort_published_up),
            formatMessage(messages.sort_updated_down),
            formatMessage(messages.sort_updated_up),
            formatMessage(messages.sort_skill_down),
            formatMessage(messages.sort_skill_up)];
            

            let sortby = sortOptionsLessons.map((element, index) => {
                return <option key={index} value={index}>{element}</option>
            });
            

        return (
            <select value={this.props.value} onChange={this.selectSort} >
                {sortby}
             </select>
        );
    }
}

export default injectIntl(SortLessons);

