import React, { Component } from 'react';
import Block from '../containers/Block';

class Panel extends Component {
    render() {

        const {data, collapsible, unit, pageID, blockIndex} = this.props;
        const {content, title} = data;
        let pageNumber = '';
        /* ELEMENT ID CONSTRUCTOR */
        if (pageID)
        {
            pageNumber = pageID.replace(/-/g, '');
        }
        else
        {
            pageNumber = '--';
        }
        const elementID = `${unit}${pageNumber}${blockIndex}`;
        

        if (collapsible) {
            return (
            <div id={`panel-${elementID}`} className='panel panel-primary'>
            <div className='panel-heading'>
                <h4 className='panel-title'><a data-toggle="collapse" href={`#collapse-${elementID}`}>{title}<span className="glyphicon glyphicon-plus-sign pull-right"></span></a></h4>
            </div>
            <div id={`collapse-${elementID}`} className='panel-collapse collapse in'>
                <div className='panel-body'>
                {content.map((contentBlock, index) =>
                    <Block key={index} data={contentBlock} />)
                }
                </div>
            </div>
            </div>
        )
        } else {
            return (
            <div id={`panel-${elementID}`} className='panel panel-info'>
                <div className='panel-heading'>
                    <h4 className='panel-title'>{title}</h4>
                </div>
                <div className='panel-body'>
                {content.map((contentBlock, index) =>
                    <Block key={index} data={contentBlock} />)
                }
                </div>
            </div>
            )
        }
        
    }
}

export default Panel;