import React, {Component} from 'react';
import { connect } from 'react-redux';

class Heading extends Component {

render(){
	const {data, unit, pageID, pages} = this.props;

	let levelOne = pageID.split("-")[0];
	let levelTwo = pageID.split("-")[1];
	let levelThree = pageID.split("-")[2];

	const intL1 = parseInt(levelOne, 10);
	const intL2 = parseInt(levelTwo, 10);
	const intL3 = parseInt(levelThree, 10);

	let L1Parent = `${levelOne}-0-0`;
	let L2Parent = `${levelOne}-${levelTwo}-0`;

	let L1Title = pages[unit][L1Parent][0].content;
	let L2Title = pages[unit][L2Parent][0].content;
	

	/* Create Heading with or w/o breadcrumbs for L1 */
	if ((intL1 > 0) && (intL2 === 0) && (intL3 === 0))
	{
		return( <h3 className="sub_head">{data}</h3> )
	}
	else if ((intL1 > 0) && (intL2 > 0) && (intL3 === 0))
	{
		return ( <h3 className="sub_head">
					<small>{L1Title} »</small>{data}</h3> )
	}
	else if ((intL1 > 0) && (intL2 > 0) && (intL3 > 0))
	{
		return ( <h3 className="sub_head"><small>{L1Title} » {L2Title} »</small> {data}</h3> )
	}


}
}

const mapStateToProps = (state) => {
    return state.module.content;
}

export default connect(mapStateToProps)(Heading);
