import React, { useEffect } from 'react';
//import { useEffect } from 'react';
import { connect } from 'react-redux';
import {  useParams } from 'react-router-dom';
import { getModuleDataFor, setDevMode } from '../actions';

import Header from './Header';
import Section from './Section';
import Footer from './Footer';
import Sidebar from './Sidebar';

import BarLoader from 'react-spinners/BarLoader';
import DeveloperTools from './DeveloperTools';


const UnitContainer = props => {
    const { dispatch, devMode } = props;
    
    let urlParams = useParams();
    const { lessonId, unitId} = urlParams;
    

    useEffect(() => {
        dispatch(getModuleDataFor(lessonId));
        if (devMode){
            dispatch(setDevMode(true));
        }
        else {
            dispatch(setDevMode(false));
        }
    }, [dispatch, lessonId, devMode]);

    return (
        <>
            {props.module.ready
                ? <Header breadcrumbs={true} metadata={props.module.metadata} activeUnit={unitId} units={props.module.content.units} />
                : null
            }
            <div className='wrapper right-open'>
            <div className='container-fluid'>
            <div className='row'>
                <div className='col-body'>

                    {/* MAIN CONTENT SECTION :: Displays  UNIT */}
                    <main className='col-content content'>
                        {props.module.ready
                            ? 
                            <>
                            {/* TODO: display current unit only --> */}
                            {props.module.content.units.filter(unit => parseInt(unit.index) === parseInt(unitId)).map((unit, index) => 
                                <div key={index} id={`unit-${unit.index}`} className='unit-section'>
                                    {unit.title.length > 0 
                                        ? <h1 className='unit_title'>{unit.title}</h1>
                                        : <p className='builder-error'>No unit/chapter title found!</p>
                                    }
                                    <Section unit={unit.index} menu={props.module.content.units} data={props.module.content.pages[unit.index]} />
                                </div>
                            )}
                            </>
                            :
                            <div className='padded-loader center-block'>
                                Loading...
                                <BarLoader color='#bbb' />
                            </div>
                        }
                    </main>

                    {/* LEFT SIDEBAR */}
                    {props.module.ready
                    ? <Sidebar menu={props.module.content.units} activeUnit={unitId} />
                    : <Sidebar menu={null} />                        
                    }
                    

                    {/* RIGHT SIDEBAR */}
                    <DeveloperTools />

                </div>
            </div>
            </div>
            </div>


            <Footer />           
        </>
    );
}


const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitContainer);