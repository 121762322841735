import React, { Component, Fragment } from 'react';
//import reactStringReplace from 'react-string-replace';
import Block from '../containers/Block';

class SelectQuestion extends Component {
    
    render() {
        const {unit, pageID, blockIndex} = this.props;
        const {feedback, questionContent, questions} = this.props.data;
        const alphabet = 'abcdefghijklmnopqrstuvwxyz';
        let pageNumber = '';
        
        /* QUESTION ID CONSTRUCTOR */
        if (pageID)
        {
            pageNumber = pageID.replace(/-/g, '');
        }
        else
        {
            pageNumber = '--';
        }
        const elementID = `${unit}${pageNumber}${blockIndex}`;



        /* DESTRUCTURED ARRAYS OF STRINGS WITH "_____" PLACEHOLDERS
        const buildQuestionInterlace = (textString, dropdownArray) => {
            let elementConstruct = <strong>--SELECT--</strong>;
            //let deconstructedString = textString.replace(/_____/g, elementConstruct);
            

            return elementConstruct;
        } */

        /*TODO:: All dropdowns been shown for each replacement _____ set */
                   

        return (
            <div id={`q${elementID}`} className='panel panel-default interaction-question'>
                <div className='panel-body'>
                <div className='question-wrap inlinedropdowns'>
                    <h4>Question</h4>
                    {questionContent.map((contentBlock, index) =>
                        <Block key={index} data={contentBlock} />)
                    }
                    <form action='#'>
                        <fieldset>
                        {questions.map((drops, Qindex) =>
                            <Fragment key={`q${Qindex}`}>
                            <div className='response-group-item'>
                               <p><strong>{alphabet.charAt(Qindex)}) </strong> {drops.question}</p>

                                {/*reactStringReplace(drops.question, /(_____)/g, (match, i) => (
                                    <Fragment key={`ddpart-${i}`}>
                                    {drops.dropdowns.map((selector, Dindex) => 
                                        <select key={`s${Qindex}${Dindex}`} name={`q${elementID}-${Qindex}${Dindex}`} whatever={offset}>
                                            <option>--</option>
                                            {selector.answers.map((answer, Oindex) =>
                                                <option key={`o${Oindex}`}>{answer}</option>
                                            )}
                                        </select>
                                    )}
                                    </Fragment> ))
                                */}
                                
                                
                                {drops.dropdowns.map((selector, Dindex) => 
                                    <select key={`s${Qindex}${Dindex}`} name={`q${elementID}-${Qindex}${Dindex}`}>
                                        <option>--</option>
                                        {selector.answers.map((answer, Oindex) =>
                                            <option key={`o${Oindex}`}>{answer}</option>
                                        )}
                                    </select>
                                )}                   
                            </div>
                            <div className='correct response-group-item dropdown_correct answer'>
                                <p>
                                {drops.dropdowns.map((selector, i) => 
                                    <Fragment key={`a${i}`}>{i !== 0 ? ', ' : ''}{selector.answers[selector.correct]}</Fragment>
                                )}
                                </p>
                            </div>
                            </Fragment>
                        )}
                       
                        <input className="submit-button btn btn-primary" name="submit-button" value="Done" type="button"></input>
                        </fieldset>
                    </form>
                    <div className='answer'>
                    {feedback.map((contentBlock, index) =>
                        <Block key={index} data={contentBlock} />)
                    }
                    </div>
                    <div className="message">Please make a selection.</div>
                </div>
                </div>
            </div>
        );
    }
}

export default SelectQuestion;