import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';


const messages = defineMessages({
    all_languages: {
        id: 'all_languages',
        defaultMessage: 'All Languages'
    }
})

class FilterLanguage extends Component {

    selectLanguage = (e) => {
        this.props.selectLanguage(e.target.value);
	}
		

    render() {

        const {  formatMessage } = this.props.intl;

        //Layor in the stuff not comming from the db
        let languageTitle = formatMessage(messages.all_languages);
        let languages = [{
            "id": "0",
            "translation": languageTitle
        }, ...this.props.languages];

       
        let languageRows = languages.map(element => {
            return <option key={element.id} value={element.id}>{element.translation}</option>
        });

        return (
            <select value={this.props.value} onChange={this.selectLanguage} >
                {languageRows}
             </select>
        );
    }
}

export default injectIntl(FilterLanguage);