import React from 'react';
import LanguageMenu from '../components/LanguageMenu.react'; 

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='container-fluid'>
                <div className='bottom_footer'>
                <div className="footer-copyright col-xs-12 col-md-6">
                    <LanguageMenu id="footer-languages-drop" dropup={true} /> 
                    <p><strong>Education and Training</strong></p>
                    <p id="lesson-copyright">© 2022, <a href="https://www.ucar.edu/">The University Corporation for Atmospheric Research</a> - All Rights Reserved.</p>
                </div>
                <div className="footer_nav col-xs-12 col-md-6">
                    <nav className="footer_sub_nav">
                        <ul className="menu">
                            <li><a href="./" target="_blank">Legal Notices</a></li>
                            <li><a href="./" target="_blank">COMET</a></li>
                            <li><a href="./" target="_blank">MetEd</a></li>
                        </ul>
                    </nav>
                </div>
                </div>
            </div>
        </footer>

       
    );
};

export default Footer;