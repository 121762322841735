import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { setLocale } from '../actions'


const messages = defineMessages({
	english_label: {
		id: 'lang.english_label',
		description: '',
		defaultMessage: 'English'
	},
	spanish_label: {
		id: 'lang.spanish_label',
		description: '',
		defaultMessage: 'Español'
	},
	portuguese_label: {
		id: 'lang.portuguese_label',
		description: '',
		defaultMessage: 'Português'
	},
	chinese_label: {
		id: 'lang.chinese_label',
		description: '',
		defaultMessage: '中文'
	},
	french_label: {
		id: 'lang.french_label',
		description: '',
		defaultMessage: 'Français'
	},
	russian_label: {
		id: 'lang.russian_label',
		description: '',
		defaultMessage: 'Русский'
	},
	auto_label: {
		id: 'lang.auto_label',
		description: '',
		defaultMessage: 'Auto Dectect'
	},
	language: {
		id: 'language.label',
		description: '',
		defaultMessage: 'Language'
	}
	
})

class LanguageMenu extends React.Component
{	  
	
	setLocale = (e) => {
		this.props.dispatch(setLocale(e.target.value));
	}

	render() 
	{		  
		const {formatMessage} = this.props.intl;
		
		var locale = this.props.viewer.language.locale;

		
		var lis = this.props.viewer.language.locale_codes.map(function(item, index) 
 		{	 
			
			var label = "Not set"
			switch (item) 
			{
			  case "auto":
				  label = formatMessage(messages.auto_label);
			    break;
			  case "en_EN":
				  label =  formatMessage(messages.english_label);
			    break;
			  case "es_ES":
				  label =  formatMessage(messages.spanish_label);
			    break;
			  case "fr_FR":
				  label =  formatMessage(messages.french_label);
			    break;
			  case "pt_BR":
				  label =  formatMessage(messages.portuguese_label);
				  break;
			  case "ru_RU":
				  label =  formatMessage(messages.russian_label);
				  break;
			  case "zh_TW":
				  label =  formatMessage(messages.chinese_label);
				  break;
				  
				default:
					break;
			}
 				
			if(locale === item)
			{
				return( <option key={item} value={item}  >{label}</option>  );

			}else
			{
				return( <option key={item} value={item} >{label}</option>    );
			}
		});
			
		return (  	  
				<select onChange={this.setLocale} title={formatMessage(messages.language)}  >
						{lis}
				</select>
		  		);
	}
}

LanguageMenu.propTypes = {
		module: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired,
		viewer: PropTypes.object.isRequired,

		dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	const { module, user, viewer } = state;
  return { module: module, user: user, viewer: viewer };
}

export default LanguageMenu = injectIntl(connect(mapStateToProps)(LanguageMenu))









	

