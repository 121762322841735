//REDUCER
//import {  START_MEDIA_DOWNLOAD, UPDATE_MEDIA_DOWNLOAD, STOP_MEDIA_DOWNLOAD, SET_LOGIN_STATUS, SET_QUEUE_STATUS, RECIEVE_REVIEWS, SAVING_REVIEWS, SHOW_SCHEMA_ERROR, SHOW_ERROR_PAGE, RECIEVE_VIEWER, SET_SERVER_OK, SHARE_SCORE_RESULTS, SET_LOCALE, SET_USER_INITIALIZED, SET_CATALOG_INITIALIZED, SET_LESSON_INITIALIZED, SET_COURSE_INITIALIZED, SET_SURVEY_PAGE, SET_REDIRECT_PAGE, SET_LOGIN_FEEDBACK, RECIEVE_LOGOUT, SET_SPOTTER_CHECKBOX_STATUS, SET_SPOTTER_COUNTY_STATE } from '../actions'
import { RECIEVE_VIEWER, SET_LOCALE } from '../actions';
import { createAction, createReducer } from '@reduxjs/toolkit'

const initialState =
{
	fetchError: null,
	schemaError: null,
	user_initialized: false,
	lesson_initialized: null,
	catalog_initialized: false,
	course_initialized: null,
	redirect_page: null,
	login_feedback: null,
	login_is_loading: false,
	queue_is_loading: false,
	language: { locale: "en_EN",
				//locale_codes: ["auto", "en_EN", "es_ES", "fr_FR", "zh_TW", "pt_BR" ]},
				locale_codes: ["auto", "en_EN", "fr_FR", "es_ES", "pt_BR", "ru_RU", "zh_TW"  ]}, //Turn off till we have all the translations. 
	survey: {page: 1},
	shareScoreSuccess: null,
	savingReviewUnderway: false,
	server: getServer(),
	downloadStatus: {}, //new Map(), Maps have issues with immutability apperently. 
	spotter_is_loading: false,
	spotter_county_loading_state: 0
}

const viewer = createReducer(initialState, (builder) => {
	builder
	  .addCase(createAction(RECIEVE_VIEWER), (state, action) => {
		  state.language.locale = action.json.language.locale;
	  })
	  .addCase(createAction(SET_LOCALE), (state, action) => {
		state.language.locale = action.locale;
	})
	 
  })

export default viewer 

  /*
function viewer(
	state = {
		fetchError: null,
		schemaError: null,
		user_initialized: false,
		lesson_initialized: null,
		catalog_initialized: false,
		course_initialized: null,
		redirect_page: null,
		login_feedback: null,
		login_is_loading: false,
		queue_is_loading: false,
		language: { locale: "auto",
					//locale_codes: ["auto", "en_EN", "es_ES", "fr_FR", "zh_TW", "pt_BR" ]},
					locale_codes: ["auto", "en_EN", "fr_FR", "es_ES", "pt_BR", "ru_RU", "zh_TW"  ]}, //Turn off till we have all the translations. 
		survey: {page: 1},
		shareScoreSuccess: null,
		savingReviewUnderway: false,
		server: getServer(),
		downloadStatus: new Map(),
		spotter_is_loading: false,
		spotter_county_loading_state: 0
	}, action) 
{
  
  let s = {...state};
  
  switch (action.type) 
  {
	
	case START_MEDIA_DOWNLOAD:
		s.downloadStatus.set(action.id, {status: action.status, progress: action.progress, error: "", timer: action.timer})
		return s;

	case UPDATE_MEDIA_DOWNLOAD:
		let download = s.downloadStatus.get(action.id);
		download.status = action.status;
		download.progress = action.progress;
		download.error = action.error;

		s.downloadStatus.set(action.id, download);
		return s;

	case STOP_MEDIA_DOWNLOAD:
		let download2 = s.downloadStatus.get(action.id);
		clearInterval(download2.timer);
		return s;

	case SHOW_ERROR_PAGE:
		var newState = {...state};
		newState.fetchError = action.error;
		return newState;

	case SHOW_SCHEMA_ERROR:
		var newState = {...state};
		newState.schemaError = action.error;
		return newState;	

  	case RECIEVE_VIEWER:
	  	s.language.locale = action.json.language.locale;
		  return s;
		  
	case SAVING_REVIEWS:
	  	s.savingReviewUnderway = true;
		  return s;	
		  	  
	case RECIEVE_REVIEWS:
	  	s.savingReviewUnderway = false;
		  return s;
		  
  	case SET_SERVER_OK:
	  	return s;
	  	
	case SET_LOCALE:
	  	s.language.locale = action.locale;
	  	return s;
	  
	case SET_USER_INITIALIZED:
	  	s.user_initialized = action.bool;
		return s;

	case SET_LESSON_INITIALIZED:
			s.lesson_initialized = action.id;
		  return s;

	case SET_CATALOG_INITIALIZED:
			s.catalog_initialized = action.bool;
		  return s;	  
		  
	case SET_COURSE_INITIALIZED:
			s.course_initialized = action.id;
		  return s;	  

  	case SET_SURVEY_PAGE:
	  	s.survey.page = action.page;
      return s;
      
    case SET_REDIRECT_PAGE:
	  	s.redirect_page = action.page;
	  	s.login_feedback = null;
        return s;
	        
    case SET_LOGIN_FEEDBACK:
		//Establish the baseline.
	  	s.login_feedback = action.feedback;
		return s;        

	case SET_LOGIN_STATUS:
		s.login_is_loading = action.isLoading;
		return s; 

	case SET_SPOTTER_CHECKBOX_STATUS:
		s.spotter_is_loading = action.isLoading;
		return s; 
		
	case SET_SPOTTER_COUNTY_STATE:
		s.spotter_county_loading_state = action.state;
		return s; 

	case SET_QUEUE_STATUS:
		s.queue_is_loading = action.isLoading;
		return s; 

	case RECIEVE_LOGOUT:
		s.login_feedback = null;
		return s;
        
    case SHARE_SCORE_RESULTS:
    	if(action.json.returnType === "success")
		{
    		s.shareScoreSuccess = true;
		}else
		{
			s.shareScoreSuccess = false;
		}
	  	
	    return s;   
	
    default:
      return state
  }
}
*/

function getServer()
{
	let useDeved = Boolean(
		window.location.hostname === 'deved.meted.ucar.edu' ||
		window.location.hostname === 'localhost' ||
		  // [::1] is the IPv6 localhost address.
		  window.location.hostname === '[::1]' ||
		  // 127.0.0.1/8 is considered localhost for IPv4.
		  window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		  )
	  );
	
	if (useDeved) 
	{
		return "https://deved.meted.ucar.edu/";
	}else
	{
		return "https://www.meted.ucar.edu/";
	}
	
	
}