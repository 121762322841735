import React from 'react';
import Block from './Block';


function Page(props) {
    const {unit, pageID, dev} = props;
    const destructuredID = pageID.replace(/-/g, '');

    return (
        <div id={`page_${props.pageID}`} className='page'>
        {props.content.map((contentBlock, index) =>
            <Block key={`block_${unit}${destructuredID}${index}`} data={contentBlock} unit={props.unit} pageID={props.pageID} blockIndex={index} dev={dev} />)
        }
        </div>
    );
}

export default Page;