import React, { Component } from 'react';
import Block from '../containers/Block';

class TextEntryQuestion extends Component {
    
    render() {
        const {unit, pageID, blockIndex} = this.props;
        const {feedback, question} = this.props.data;
        let pageNumber = '';
        /* QUESTION ID CONSTRUCTOR */
        if (pageID)
        {
            pageNumber = pageID.replace(/-/g, '');
        }
        else
        {
            pageNumber = '--';
        }
        const elementID = `${unit}${pageNumber}${blockIndex}`;

        return (
            <div id={`q${elementID}`} className='panel panel-default interaction-question'>
                <div className='panel-body'>
                <div className='question-wrap textentry'>
                    <h4>Question</h4>
                    {question.map((contentBlock, index) =>
                        <Block key={index} data={contentBlock} />)
                    }
                    <form action='#'>
                        <fieldset>
                        <textarea id={`q${elementID}`} className="textbox" name={`q${elementID}`} cols="60" rows="4"></textarea>
                        <br/>
                        <input className="submit-button btn btn-primary" name="submit-button" value="Done" type="button"></input>
                        </fieldset>
                    </form>
                    <div className='answer'>
                    {feedback.map((contentBlock, index) =>
                        <Block key={index} data={contentBlock} />)
                    }
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default TextEntryQuestion;