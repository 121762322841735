import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
//import ReactCompareImage from 'react-compare-image'


class Sweeper extends React.Component
{

  render()
	{

        let beforeItem = parseInt(this.props.before, 10);
        let afterItem = parseInt(this.props.after, 10);
        let beforeEntry = this.props.mediaItems[beforeItem];
        let afterEntry = this.props.mediaItems[afterItem];
        let tempURL = 'https://cdn.meted.ucar.edu';
        let beforeSrc = '';
        let afterSrc = '';
        

		/*let useDeved = Boolean(
			window.location.hostname !== 'www.meted.ucar.edu' 			
		  );*/

		if (typeof(beforeEntry) === "undefined" || typeof(afterEntry) === "undefined")
		{
			return( <div className="alert alert-danger" role="alert">
						<p>MediaItem id in Google Doc not found in MSDB list for Image Sweep interaction.</p>
                        { typeof(beforeEntry) === "undefined"
                            ? <p>Check item <strong>BEFORE IMAGE: <a href={`https://outside.comet.ucar.edu/activeprojects_2/mediaItem.php?fileID=${beforeEntry}`} target="_blank" rel='noreferrer'>{this.props.before}</a></strong></p>
                            : null
                        }
                        { typeof(afterEntry) === "undefined"
                            ? <p>Check item <strong>AFTER IMAGE: <a href={`https://outside.comet.ucar.edu/activeprojects_2/mediaItem.php?fileID=${afterEntry}`} target="_blank" rel='noreferrer'>{this.props.after}</a></strong></p>
                            : null
                        }
						
					</div>);
		}else
		{
            

			/*if (useDeved) 
			{
				tempURL = 'https://deved.meted.ucar.edu';
			}*/

            // Define final URLs to use
			beforeSrc = tempURL + "/" + beforeEntry.image_url + "/" + beforeEntry.file_name + "." + beforeEntry.extension;
            afterSrc = tempURL + "/" + afterEntry.image_url + "/" + afterEntry.file_name + "." + afterEntry.extension;
			const maxItemWidth = beforeEntry.width;
            let maxWidthStyles = parseInt(maxItemWidth, 10) !== 0 ? {maxWidth: `${maxItemWidth}px`} : {};	
            
            return (
                <div className="image-sweep center-block" style={maxWidthStyles}>
                    {/*<ReactCompareImage leftImage={beforeSrc} rightImage={afterSrc} />*/}
                    <img className="img-responsive center-block" src={beforeSrc} alt={beforeEntry.description} />
                    <img className="img-responsive center-block" src={afterSrc} alt={afterEntry.description} />
                </div>
            )
		}
  }
}

Sweeper.propTypes = {
	mediaItems: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
const { module } = state;
return {  mediaItems: module.mediaItems};
}


export default connect(mapStateToProps)(Sweeper)