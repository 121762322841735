import React, { Component } from 'react';
import { connect } from 'react-redux';
//import {Link} from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import { setQuery, getCatalog, setTopicPrune, setLanguagePrune, setSort } from '../actions';
import ResourceCard from '../components/ResourceCard';
import PromoBanner from '../components/PromoBanner';
import LoadingPadded from '../components/LoadingPadded';
import FilterTopics from '../components/FilterTopics';
import FilterLanguage from '../components/FilterLanguage';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import SortLessons from '../components/SortLessons';


const messages = defineMessages({

	lessons_resources: {
		id: 'lessons_resources',
		defaultMessage: 'Lessons/Resources'
	},
	courses: {
		id: 'courses',
		defaultMessage: 'Courses'
	},
	ucourses: {
		id: 'ucourses',
		defaultMessage: 'University Course Support'
	},
    find_titles: {
		id: 'find_titles',
		defaultMessage: 'Find lesson titles in these results'
	}
})

class Catalog extends Component {

    state = {
        allResourcesCap: 12,
        activeResource: 'lessons',
    }

    componentDidMount(){
        const {dispatch} = this.props;
        //dispatch(initAccess());
        dispatch(getCatalog());
    }

    queryChange = (event) => {
		this.props.dispatch(setQuery(event.target.value));
	}

    selectTopic = (meta_id) => {

		this.props.dispatch(setTopicPrune(meta_id));
	}

    selectLanguage = (id) => {
		this.props.dispatch(setLanguagePrune(id));
	}

    selectSort = (id) => {
		this.props.dispatch(setSort(id));
	}

    render() {
        const ready= this.props.catalog.ready;
        const modules = this.props.catalog.searchModules;
        const {logged_in} = this.props.user;
        let moduleCount = ready ? modules.length : 0;
        //let courseCount = ready ? courses.length : 0;

        const {  formatMessage } = this.props.intl;

        return (
            <div id='catalog'>
            <Header breadcrumbs={false} />
            
            <section className="hero container search-hero">
                <div className="row justify-content-center">
                <div className="col-xs-12 text-center">
                    <h2 id='catalog-title' className="text-3xl font-bold pb-4"><FormattedMessage id='catalog' defaultMessage="Education &amp; Training Catalog" /></h2>
                </div>
                </div>
                <div className="row justify-content-center">
                <form action="/search/" method="get" className="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6">
                    <label className="sr-only" htmlFor="st-search-input">Search</label>
                    <input onChange={this.queryChange} id="st-search-input" className="form-control" name="q" placeholder={formatMessage(messages.find_titles)} type="search" autoComplete="off" spellCheck="false" dir="auto" autoFocus/>
                    <div id="autocompleteResults"></div>
                    <div className='p-5'>
                        <FormattedMessage id="catalog.filter" defaultMessage="Filter" />
                        <FilterTopics value={this.props.catalog.pruneTopic} topics={this.props.catalog.topics} selectTopic={this.selectTopic}/>
                        <FilterLanguage value={this.props.catalog.pruneLanguage} languages={this.props.catalog.languages}  selectLanguage={this.selectLanguage}/>
                        <SortLessons  value={this.props.catalog.sortOptionSelected} selectSort={this.selectSort}/>
                    </div>
                </form>
                </div>
            </section>

            {/* NEW TABS BAR */}
            <section id='resource-selector'>
                <div className='container'>
                    <ul className='nav nav-pills'>
                        <li className='nav-item'><a className='nav-link active' href='/'>Lessons / Resources <span className="caret arrow"></span></a></li>
                        <li className='nav-item'><a className='nav-link' href='/'>Courses <span className="caret arrow"></span></a></li>
                        <li className='nav-item'><a className='nav-link' href='/'>University Course Support <span className="caret arrow"></span></a></li>
                    </ul>
                </div>
            </section>

           

            {/* TABBED CATALOG CONTENT */}
            {
            ready && modules.length > 0 ?
            <section className='hero row intro-hero'>
                <div className="container justify-content-left">
                    <h4>Explore lessons and resources</h4>
                    <p className='result-counter small'>({moduleCount})</p>

                    <div className='all-modules'>
                        {modules.map((item, index) => index < this.state.allResourcesCap
                            ? <ResourceCard key={`rc-${index}`} data={item} />
                            : null
                            )                      
                        }
                    </div>
                    <div className='container'>
                        <a href='/' className='btn btn-primary'>Explore all lessons &rarr;</a>
                    </div>
                </div>
            </section>
            :
            <section className='loading-section'>
                <div className='hero'>
                    <LoadingPadded />
                </div>
            </section>
            }

            {/* Display promo banner if logged in */}
            {logged_in
            ? <PromoBanner member={true} />
            : null}

            <Footer version="full" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return { dispatch };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Catalog));