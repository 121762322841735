import React from 'react';
import Page from './Page';

function Section(props) {
    let pagesContentArray = Object.entries(props.data);

    return (
        <section className='unit-section'>
            {pagesContentArray.map((page, index) => 
                <Page key={`${props.unit}.page_${page[0]}`} unit={props.unit} pageID={page[0]} content={page[1]} pageIndex={index} dev={props.dev} />
            )}
        </section>
    );
}

export default Section;