import React, { Component } from 'react';
import { connect } from 'react-redux';
import Block from '../containers/Block';

class DrawQuestion extends Component {
    render() {

        const {blockIndex, pageID, unit} = this.props;
        const {background, colors, directions, feedback, labels, question} = this.props.data;
        let pageNumber = '';
        /* QUESTION ID CONSTRUCTOR */
        if (pageID)
        {
            pageNumber = pageID.replace(/-/g, '');
        }
        else
        {
            pageNumber = '--';
        }
        const elementID = `${unit}${pageNumber}${blockIndex}`;

        let mediaEntry = this.props.mediaItems[background];

        if (typeof(mediaEntry) === "undefined" )
		{
            return( <div className="alert alert-danger" role="alert">
						<p>MediaItem id in Google Doc not found in MSDB list for drawing interaction.</p>
                        { typeof(mediaEntry) === "undefined"
                            ? <p>Check item <strong>BACKGROUND IMAGE: <a href={`https://outside.comet.ucar.edu/activeprojects_2/mediaItem.php?fileID=${background}`} target="_blank" rel='noreferrer'>{background} <span className='glyphicon glyphicon-new-window'></span></a></strong></p>
                            : null
                        }
					</div>);
        }
        else
        {
            return (
            <div id={`draw-${elementID}`} className='drawing-question panel-body' data-image={`${mediaEntry.file_name}.${mediaEntry.extension}`} data-colors={colors.map((color, i) => (i !== 0 ? ` ${color}` : color))} data-labels={labels.map((label, i) => (i !== 0 ? ` ${label}` : label))}>
                <h4>Exercise</h4>
                <p className='question'>{question}</p>
                <p className='directions'>{directions}</p>
                <div className='answer'>
                {feedback.map((elem, index) =>
                    <Block key={index} data={elem} />
                )}
                </div>
            </div>
        );
        }
    }
}

const mapStateToProps = (state) => {
    const { module } = state;
    return {  mediaItems: module.mediaItems};
}

export default connect(mapStateToProps)(DrawQuestion);