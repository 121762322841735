import React from 'react'


export default class Table extends React.Component 
{
	
	render()
	{
		
		var table = [];
		
		this.props.data.forEach( (row, index) => 
		{
			var tds = [];
			row.forEach( (cell, index) => 
			{
				tds.push(<td key={index} >{cell.trim()}</td>);
			});
			
			table.push(<tr key={index}  >{tds	}</tr>);	
		});	
	
		return(
				<table className="table table-responsive table-bordered">
					<tbody>
					 {table}
					</tbody>
				</table>
		)
	}
}

