import React from 'react';

const TopNavMenu = () => {
    return (
        <div className="nav-container hidden-sm hidden-xs">
            {/*<div>
                <ul className="nav navbar-nav navbar-right">
                <li>Quiz</li>
                <li>Survey</li>
                <li>Booster</li>
                <li className="dropdown"><a href="#more-submenu" data-toggle="collapse" role="button">More <i class="caret"></i></a>
                    <ul id="more-submenu" class="nav navar-nav dropdown-menu" role="menu">
                    <li>Download</li>
                    <li>Gallery</li>
                    </ul>
                </li>
                </ul>
            </div>*/}
        </div>
    );
};

export default TopNavMenu;