//REDUCERS
import { combineReducers } from 'redux'

import catalog from './catalogSearch'
import devtools from './devTools';
import viewer from './viewer';

import {
	RECEIVE_USER_STATUS,
	RECEIVE_MODULE_DATA
} from '../actions';

const initialUserStructure = {
	logged_in: false,
	userName: '',
	firstName: '',
	lastName: '',
	email: '',
	uid: ''
}

const initialModuleStructure = {
	ready: false,
	metadata : {},
	content: {},
	descriptions: {},
	objectives : {},
	contributors : {},
	addendums : {},
	reviews: {},
	mediaItems: {},
}


function user (state=initialUserStructure, action) {
	const { user } = action;

	switch (action.type) {
		case RECEIVE_USER_STATUS:
				return {...user};
		default:
				return state;
	}
}

function module (state=initialModuleStructure, action) {
	const { module } = action;

	switch (action.type) {
        case RECEIVE_MODULE_DATA:
            return { ready: true, ...module }			
        default:
            return state;
    }
}


export default  combineReducers({
	user,
	catalog,
    module,
	devtools,
	viewer

})
