import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import BarLoader from 'react-spinners/BarLoader';

class Sidebar extends Component {


    render() {
        const {menu, activeUnit, module, devtools} = this.props;
        const {metadata} = module;
        const {devMode} = devtools;
        let activeMode = devMode ? 'dev' : 'lesson';

        return (
            <nav className='col-nav'>
                <div id='sidebar-nav' className='sidebar hidden-sm hidden-xs'>
                    <div id='navbar' className='nav-sidebar'>
                    {Array.isArray(menu) 
                    ?   <ul id='tab_nav' className='nav unit-dropdown-ul' role='menu'>
                        {activeUnit !== null
                        //IF ACTIVE UNIT NOT NULL: Display expanded unit menu
                        ? <>{menu.filter(unit => parseInt(unit.index) === parseInt(activeUnit)).map((unit, unitIndex) =>
                            <li key={unitIndex}>
                                <a className={`ul-ch-toggle collapsed ${activeUnit === unitIndex ? 'active': null}`} href={`#unit-${unit.index}`}>{unit.index +'. ' + unit.title} <span className='caret arrow'></span></a>
                                <div className='chapter-toc collapse in' aria-expanded='true'>
                                    <ul className='nav lc-docs-sidenav'>
                                    {unit.menu.map((page, pageIndex) =>
                                        <Fragment key={pageIndex}>
                                        {page.children.length > 0
                                        ?
                                        <li><a href={`#page_${page.page}`} title={page.title}>{page.title}</a>
                                            <ul id={`ul_${page.page}`} className='nav'>
                                                {page.children.map((subpage, subpageIndex) =>
                                                <li key={subpageIndex}><a href={`#page_${subpage.page}`} title={subpage.title}>{subpage.title}</a>
                                                    {subpage.children.length > 0
                                                    ? 
                                                    <ul id={`ul_${subpage.page}`} className='nav'>
                                                        {subpage.children.map((subsubpage, subsubpageIndex) =>
                                                            <li key={subsubpageIndex}><a href={`#page_${subsubpage.page}`} title={subsubpage.title}>{subsubpage.title}</a></li>
                                                        )}
                                                    </ul>
                                                    : null
                                                    }
                                                </li>
                                                )
                                                }
                                            </ul>
                                        </li>
                                        : <li key={pageIndex}><a href={`#page_${page.page}`} title={page.title}>{page.title}</a></li>
                                        }
                                            
                                        </Fragment>
                                    )}
                                    </ul>
                                </div>
                            </li>
                        )}</>
                        //IF ACTIVE UNIT IS NULL:: Display only units
                        : <>{menu.map((unit, unitIndex) =>
                            <li key={unitIndex} className='menu-item-unit'>
                                <span className='unit-number'>{unit.index}</span>
                                <a className={`ul-ch-toggle collapsed`} href={`/${activeMode}/${metadata.id}/${unit.index}`} title={unit.title}>{unit.title}</a>
                                {/*<div className='chapter-toc collapse' aria-expanded='false'>
                                    <ul className='nav lc-docs-sidenav'>
                                    {unit.menu.map((page, pageIndex) =>
                                        <Fragment key={pageIndex}>
                                        {page.children.length > 0
                                        ?
                                        <li><a className={`haschild ${pageIndex === 0 ? 'active' : null}`} href={`#page_${page.page}`} title={page.title}>{page.title}</a>
                                            <ul className='nav'>
                                                {page.children.map((subpage, subpageIndex) =>
                                                <li key={subpageIndex}><a className='' href={`#page_${subpage.page}`} title={subpage.title}>{subpage.title}</a></li>
                                                )
                                                }
                                            </ul>
                                        </li>
                                        : <li key={pageIndex}><a className={`${pageIndex === 0 ? 'active' : null}`} href='./' title={page.title}>{page.title}</a></li>
                                        }
                                            
                                        </Fragment>
                                    )}
                                    </ul>
                                    </div>*/}
                            </li>
                        )}</>
                        }

                        </ul>
                    : <div className='padded-loader center-block'><BarLoader color='#bbb' /></div>
                    }
                        
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

