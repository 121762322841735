import React, {Component} from 'react'
//import ReactTooltip from 'react-tooltip'


class Paragraph extends Component {

	render() {
		const {data} = this.props;

		function chunkParse(chunk, i) {
			const {text} = chunk;
			if (chunk.type === "text") {
				return <>{text}</>
			} else {
				var properties = chunk.type;
				
				if (properties.indexOf("bold") !== -1){
					return <b>{text}</b>;
				}
				if (properties.indexOf("link") !== -1){
					return <a href={chunk.link} target="_blank" rel="noreferrer">{text}</a>;
				}
				if (properties.indexOf("italic") !== -1){
					return <em>{text}</em>;
				}
				if (properties.indexOf("highlight") !== -1){
					return <span style={{backgroundColor: chunk.color}} >{text}</span>;
				}
				if (properties.indexOf("underline") !== -1){
					return <u>{text}</u>;
				}
				if (properties.indexOf("superscript") !== -1){
					return <sup>{text}</sup>;
				}
				if (properties.indexOf("subscript") !== -1){
					return <sub>{text}</sub>;					
				}
				else {
					return <span className='warning'>PARAGRAPH ::CHUNK:: NOT DEFINED</span>
				}
			}
			
		}


		return (<p>{
					data.map((chunk, index) => (<React.Fragment key={`chunk-${index}`}>{chunkParse(chunk)}</React.Fragment>))
				}</p>)
	}
}

export default Paragraph;