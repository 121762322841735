import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {  useParams } from 'react-router-dom';
import { getModuleDataFor, setDevMode } from '../actions';

import Header from './Header';
import Section from './Section';
import Footer from './Footer';
import Sidebar from './Sidebar';

import DeveloperTools from './DeveloperTools';
import LoadingPadded from '../components/LoadingPadded';


const LessonContainer = props => {
    const { dispatch, devMode } = props;

    let urlParams = useParams();
    const { lessonId } = urlParams;
    
    useEffect(() => {
        if (lessonId !== null) { 
            dispatch(getModuleDataFor(lessonId));
            if (devMode){
                dispatch(setDevMode(true));
            }
            else {
                dispatch(setDevMode(false));
            }
        }
        else {
            //dispatch(getModuleDataFor(1161)); //TEMP::Set as default if broken
        }
    }, [dispatch, lessonId, devMode]);


    return (
        <>
            {props.module.ready
                ? <Header breadcrumbs={true} metadata={props.module.metadata} activeUnit={null} />
                : <Header breadcrumbs={false} />
            }
            

            <div className='wrapper right-open'>
            <div className='container-fluid'>
            <div className='row'>
                <div className='col-body'>

                    {/* MAIN CONTENT SECTION :: Displays FULL LESSON */}
                    <main className='col-content content'>
                        {props.module.ready
                            ? 
                            <div className={'all-units-wrapper' + (devMode ? ' dev-mode' : '')}>
                            {props.module.content.units.map((unit, index) => 
                                <div key={index} id={`unit-${unit.index}`} className='unit-section'>
                                    {unit.title.length > 0 
                                        ? <h1 className='unit_title'>{unit.title}</h1>
                                        : <p className='builder-error'>No unit/chapter title defined!</p>
                                    }
                                    <Section unit={unit.index} menu={props.module.content.units} data={props.module.content.pages[unit.index]} dev={devMode} />
                                </div>
                            )}
                            </div>
                            : <LoadingPadded />
                        }
                    </main>

                    {/* LEFT NAVIGATION SIDEBAR */}
                    {props.module.ready
                    ? <Sidebar menu={props.module.content.units} activeUnit={null} />
                    : <Sidebar menu={null} />                        
                    }
                    

                    {/* RIGHT DEVELOPER SIDEBAR */}
                    {devMode
                    ? <DeveloperTools />
                    : null
                    }
                    

                </div>
            </div>
            </div>
            </div>


            <Footer version="developer" />           
        </>
    );
}


const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonContainer);