import React, { Component } from 'react';
import Block from '../containers/Block';

class RadioQuestion extends Component {
    
    render() {
        const {unit, pageID, blockIndex} = this.props;
        const {answers, correct, feedback, question} = this.props.data;
        const alphabet = 'abcdefghijklmnopqrstuvwxyz';
        let pageNumber = '';
        /* QUESTION ID CONSTRUCTOR */
        if (pageID)
        {
            pageNumber = pageID.replace(/-/g, '');
        }
        else
        {
            pageNumber = '--';
        }
        const elementID = `${unit}${pageNumber}${blockIndex}`;

        return (
            <div id={`q${elementID}`} className='panel panel-default interaction-question'>
                <div className='panel-body'>
                <div className='question-wrap radio'>
                    <h4>Question</h4>
                    {question.map((contentBlock, index) =>
                        <Block key={index} data={contentBlock} />)
                    }
                    <form action='#'>
                        <fieldset>
                            {answers.map((contentBlock, index) =>
                                <div key={index} className={`response-group-item ${index === correct ? 'correct': ''}`}>
                                    <input id={`o${elementID}-${index}`} type="radio" name={`q${elementID}`} /><label htmlFor={`o${elementID}-${index}`}> <strong>{alphabet.charAt(index)}) </strong>{contentBlock}</label>
                                </div>)
                            }
                        <input className="submit-button btn btn-primary" name="submit-button" value="Done" type="button"></input>
                        </fieldset>
                    </form>
                    <div className='answer'>
                        <p className='response'>The correct answer is {alphabet.charAt(correct)}.</p>
                        {feedback.map((contentBlock, index) =>
                            <Block key={index} data={contentBlock} />)
                        }
                    </div>
                    <div className="message">Please make a selection.</div>
                </div>
                </div>
            </div>
        );
    }
}

export default RadioQuestion;