import React, { Component } from 'react';
import TopNavMenu from './TopNavMenu';
import LessonBreadcrumbs from './LessonBreadcrumbs';

class Header extends Component {
    render() {
        const {activeUnit, breadcrumbs, metadata, units} = this.props;
        return (
            <header>
            <nav className='nav-secondary navbar navbar-fixed-top'>
            <div className='container-fluid'>
                <div className="navbar-header">
                    <a className='app-brand' href="/">
                        <img className="logo" src="/static/img/meted_logo.png" alt="COMET Lesson Prototype Builder" title="COMET Lesson Prototype Builder" height="30" />
                    </a>
                </div>

                <div className="navbar-collapse" aria-expanded="false" style={{height:'1px'}}>

                <div className="logo-mobile">
                    <a href="/">
                        <img src="/static/img/meted_logo.png" alt="COMET / Meted Lesson" title="COMET / Meted Lesson" height="30" />
                    </a>
                </div>

                <div className="sidebar-toggle">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                </div>

                <TopNavMenu />

                {breadcrumbs
                ? <LessonBreadcrumbs data={metadata} activeUnit={activeUnit} units={units} />
                : null
                }

                </div>

            </div>
            </nav>
            </header>
        );
    }
}

export default Header;