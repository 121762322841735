import React from 'react';

function DeveloperTools(props) {
    return (
        <div className='col-toc'>
            <div id='developer-sidebar' className='sidebar hidden-xs hidden-sm'>
                <div className='toc-nav'>
                <div className='feedback-links'>
                    <h6>DEVELOPER TOOLS</h6>

                    <ul className='ready-list'>
                        <li>
                            <label className="flex items-center cursor-pointer" htmlFor='dev-pageId-input'>
                            <input id='dev-pageId-input' type="checkbox" aria-label="show page ID" className="small" /> <span className="small">page ID</span>
                            </label>
                        </li>
                        <li>
                            <label className="flex items-center cursor-pointer" htmlFor='dev-printView-input'>
                            <input id='dev-printView-input' type="checkbox" aria-label="print view" className="small" /> <span className="small">print view</span>
                            </label>
                        </li>
                        {/*<li><a href='./'>Short-Code Version</a></li>
                        <li><a href='./'>Show/Hide PHP calls</a></li>
    <li><a href='./'>Show/Hide JSON</a></li>*/}
                    </ul>
                </div>
                </div>
            </div>
        </div>
    );
}

export default DeveloperTools;