//Reducer for Developer Tools
import { SET_DEVELOPER_TOOLS, SET_DEVMODE } from "../actions";

const initialDevToolsState = {
    devMode: false,
    showPageId: false,
    showPrintView: false
}

function devtools (state=initialDevToolsState, action) {
    const {toggles, status} = action;
	switch (action.type) {
        
        case SET_DEVELOPER_TOOLS:
            return {...toggles}
        case SET_DEVMODE:
            return {...state, devMode: status}
            
        default:
            return state;
    }
}



export default devtools;