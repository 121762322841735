import React, { Component } from 'react';
import { connect } from 'react-redux';
import Block from '../containers/Block';

class DragDropQuestion extends Component {
    render() {
        const {blockIndex, pageID, unit} = this.props;
        const {background, draggables, directions, feedback, question} = this.props.data;
        let pageNumber = '';
        /* QUESTION ID CONSTRUCTOR */
        if (pageID)
        {
            pageNumber = pageID.replace(/-/g, '');
        }
        else
        {
            pageNumber = '--';
        }
        const elementID = `${unit}${pageNumber}${blockIndex}`;
        let mediaEntry = this.props.mediaItems[background];

        if (typeof(mediaEntry) === "undefined" )
		{
            return( <div className="alert alert-danger" role="alert">
						<p>MediaItem id in Google Doc not found in MSDB list for drag-drop interaction.</p>
                        { typeof(mediaEntry) === "undefined"
                            ? <p>Check item <strong>BACKGROUND IMAGE: <a href={`https://outside.comet.ucar.edu/activeprojects_2/mediaItem.php?fileID=${mediaEntry}`} target="_blank" rel='noreferrer'>{background}</a></strong></p>
                            : null
                        }
					</div>);
        }
        else
        {
            return (
            <div id={`drag-${elementID}`} className='panel panel-default interaction-question'>
                <div className='panel-body'>
                    <div className='question-wrap-drag'>
                        <h4>Question</h4>
                        <p className='question'>{question}</p>
                        <div className='drag-container' title={`media/graphics/${mediaEntry.file_name}.${mediaEntry.extension}`}>
                            <div className='image_holder'></div>
                            <p className='drag_directions'>{directions}</p>
                            <div className='drag_controls_holder'>
                                <div className='drag_controls'>
                                    {draggables.map((dragItem, dragIndex) => 
                                        <div key={dragIndex} className='draggable ui-widget-content ui-draggable drag_item ui-draggable-handle'>
                                            <span className='glyphicon glyphicon-th'></span> {dragItem}
                                        </div>
                                    )}
                                </div>
                                <input className="done_drag btn btn-primary" value="Done" type="button" /> <input className="reset_drag btn btn-default" value="Reset" type="button" />
                            </div>
                        </div>
                        <div className='answer'>
                        {feedback.map((elem, index) =>
                            <Block key={index} data={elem} />
                        )}
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
}


const mapStateToProps = (state) => {
    const { module } = state;
    return {  mediaItems: module.mediaItems};
}

export default connect(mapStateToProps)(DragDropQuestion);