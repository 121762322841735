import React, { Component } from 'react';
import {connect} from 'react-redux';

class MediaItem extends Component {
    render() {
        const {id, caption} = this.props.data;
        const devMode = this.props.dev;

        if (devMode) {
           return (
            <>
                &lt;?php echo $mm-&gt;getPrintMediaItemWrapper({id}{caption ?  `, "${caption}"` : null}); ?&gt;
            </>
            ); 
        }
        else {
            //TODO: Build MSDB viewer component
            return (
                <p style={{border: '1px solid darkRed', padding: '2px 10px', color: 'darkRed'}}><strong>MSDB Item: {id}</strong></p>
            );
        }
        
    }
}

const mapStateToProps = (state) => {
    const { module, devtools } = state;
    return {
        mediaItems: module.mediaItems,
        dev: devtools.devMode,
    };
}

export default connect(mapStateToProps)(MediaItem);