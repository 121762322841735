/* ROOT LEVEL CONTAINER */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import { getCatalog, initAccess } from '../actions';


class RootContainer extends Component {
    state = {
        lessonInput: ''
    }

    componentDidMount(){
        const {dispatch} = this.props;
        dispatch(initAccess());
        dispatch(getCatalog());
    }

    handleLessonInput(input){
        this.setState({ lessonInput: input.target.value});
    }



    render() {
        const {logged_in, userName, email, uid} = this.props.user;
        const {lessonInput} = this.state;

        return (
            <>
            <Header breadcrumbs={false} />
            
            {/* CHECK IF USER IS LOGGED IN */}
            <div className='wrapper right-open'>
            <div className='container-fluid'>
                        <section id='section-spacer'></section>

                        <section className='index-section hero row'>
                            <div className='container'>
                                <h2>General user access</h2>
                                <Link to="/catalog">Catalog &rarr;</Link>
                            </div>
                            
                        </section>

                        <section className='index-section hero row'>
                            <div className='container'>
                            <h2>Development access</h2>
                            {!logged_in
                            ? <div className='row'>
                                <div className='col-sm-4'><p><span className='indicator notready'></span>Not logged in to dev server.</p></div>
                                <div className='col-sm-8'>
                                <ul>
                                    <li><a href='localhost:8080' target='_blank' rel='noreferrer'>Log in <span className='glyphicon glyphicon-new-window'></span></a></li>
                                    <li>Refresh <span className='glyphicon glyphicon-refresh'></span></li>
                                </ul>
                                </div>
                              </div>
                            : <div className='row'>
                                <div className='col-sm-4'><p><span className='indicator ready'></span>Prototype Viewer READY</p></div>
                                <div className='col-sm-8'>
                                <ul>
                                    <li>username: {userName}</li>
                                    <li>id: {uid}</li>
                                    <li>email: {email}</li>
                                </ul>
                                </div>
                              </div>
                            }
                            
                            <p><strong>Shortcuts:</strong></p>
                            <ul>
                                <li><Link to="/dev/1161">Lesson 1161 &rarr;</Link></li>
                                <li><Link to="/dev/1408">Lesson 1408 &rarr;</Link></li>
                            </ul>
                            
                            <div className='lesson-selector'>
                                <form className='' action={`/dev/${lessonInput}`}>
                                    <div className='row'>
                                        <div className='col-4'><label htmlFor='lesson-search-input' className=''>Go to lesson:</label></div>
                                        <div className='col-8'>
                                            <input id='lesson-search-input'
                                            type="text" 
                                            value={lessonInput} 
                                            onChange={(e) => this.handleLessonInput(e)} 
                                            maxLength="8" /> <Link to={`/dev/${lessonInput}`} className="btn btn-danger btn-xs" type="submit">&rarr;</Link>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                            </div>
                        </section>
                </div>

            </div>

            <Footer version="full" />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);