import LessonContainer from './containers/LessonContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.scss';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RootContainer from './containers';
import UnitContainer from './containers/UnitContainer';
import Catalog from './containers/Catalog';
import {IntlProvider} from 'react-intl'
import * as i18n from './i18n/translatedMessages/index.js'
import { connect } from 'react-redux'
import React, { Component } from 'react';

class App extends Component {

  render() 
  {

    //Go with the language set in the module unless the user overides. 
    var locale = "en_EN";
    
    if(this.props.viewer.language.locale === "auto")
    {
      if(typeof this.props.module.metadata.locale !== 'undefined')
      {
        locale = this.props.module.metadata.locale;
      }
    }else
    {
      locale = this.props.viewer.language.locale;
    }
    
    
    var messages = i18n[locale];
    var localeWithoutRegionCode;
    if (locale !== null){
      localeWithoutRegionCode =  locale.split(/[_-]+/)[0];
    }
    else {
      localeWithoutRegionCode = "EN"
    }

    return (
      <IntlProvider key="intl" locale={localeWithoutRegionCode} messages={messages} >	
        <BrowserRouter>
          <div className="App">
            <Routes>
              <Route index element={<RootContainer />}></Route>
              <Route path="catalog/" element={<Catalog />}></Route>
              <Route path="dev/:lessonId" element={<LessonContainer devMode={true} />}></Route>
              <Route path="dev/:lessonId/:unitId" element={<UnitContainer devMode={true} />}></Route>
              <Route path="lesson/:lessonId" element={<LessonContainer devMode={false} />}></Route>
              <Route path="lesson/:lessonId/:unitId" element={<UnitContainer devMode={false} />}></Route>
            </Routes>
          </div>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);


