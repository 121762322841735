import React, { Component } from 'react';
import Block from '../containers/Block';
import PropTypes from 'prop-types';

class CheckboxQuestion extends Component {
    
    render() {
        const {unit, pageID, blockIndex} = this.props;
        const {answers, correct, feedback, question} = this.props.data;
        const alphabet = 'abcdefghijklmnopqrstuvwxyz';
        let pageNumber = '';
        
        /* QUESTION ID CONSTRUCTOR */
        if (pageID)
        {
            pageNumber = pageID.replace(/-/g, '');
        }
        else
        {
            pageNumber = '--';
        }
        const elementID = `${unit}${pageNumber}${blockIndex}`;
        
        const checkCorrectAnswers = function(answers){
            if (answers.length > 1) {
                let correctListString = '';
                for (let i = 0; i < answers.length; i++) {
                    
                    if(i === answers.length -1)
                    {
                        correctListString += `and ${alphabet[answers[i] -1]})`;
                    }
                    else {
                        correctListString += `${alphabet[answers[i] -1]}), `;
                    }
                } 

                return ' answers are ' + correctListString;
            }
            else {
                return ' answer is ' + alphabet[correct[0] - 1];
            } 
        }

        return (
            <div id={`q${elementID}`} className='panel panel-default interaction-question'>
                <div className='panel-body'>
                <div className='question-wrap checkboxes'>
                    <h4>Question</h4>
                    {question.map((contentBlock, index) =>
                        <Block key={index} data={contentBlock} />)
                    }
                    <form action='#'>
                        <fieldset>
                            {answers.map((contentBlock, index) =>
                                <div key={index} className={`response-group-item ${correct.includes(index +1) ? 'correct' : ''}`}>
                                    <input id={`o${elementID}-${index}`} type="checkbox" name={`q${elementID}`} /><label htmlFor={`o${elementID}-${index}`}> <strong>{alphabet.charAt(index)}) </strong>{contentBlock}</label>
                                </div>)
                            }
                        <input className="submit-button btn btn-primary" name="submit-button" value="Done" type="button"></input>
                        </fieldset>
                    </form>
                    <div className='answer'>
                        <p className='response'>The correct 
                            {
                                checkCorrectAnswers(correct)
                            }.
                        </p>
                        {feedback.map((contentBlock, index) =>
                            <Block key={index} data={contentBlock} />)
                        }
                    </div>
                    <div className="message">Please make a selection.</div>
                </div>
                </div>
            </div>
        );
    }
}

export default CheckboxQuestion;

CheckboxQuestion.propTypes = {
    // Block index from Page
    blockIndex: PropTypes.number,
    // Data that contains: answers, correct, feedback, question, type
    data: PropTypes.object,
    // Page ID that this block pertains to
    pageID: PropTypes.string,
    // Unit that this block pertains to (adjusted to start at 1)
    unit: PropTypes.number,
};