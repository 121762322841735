import React from 'react';

function createMarkup(html) {
    return { __html: html };
}

function ResourceCard(props) {
    
    const {data} = props;
    const {json} = data;
    const {title, description, type, module_id, image_url, titleFind} = json;
    const stringCap = 240;
    const shortDescription = description.length > stringCap ? 
                             description.substring(0, stringCap - 3) + "..." : 
                             description;


    return (
        <div item-id={module_id} className='card-holder col col-sm-6 col-lg-4 scrollable-discovery-card-spacing'>
        <div className="discovery-card" role="group" aria-label={title}>
            <a className="discovery-card-link bg-white text-black" href={`https://www.meted.ucar.edu/education_training/lesson/${module_id}`}>
                <div className="d-flex flex-column d-card-wrapper">
                    <div className='card-thumb-image' style={{backgroundImage: 'url("/static/img/cool-background.png")'}}></div>
                    <div className="d-card-hero" title={image_url}></div>
                    <div className="d-card-body pl-4 pt-4 mt-2">
                        <h5 className="h4 text-black"><span width="230"><span><span><div dangerouslySetInnerHTML={createMarkup(titleFind)} /></span></span></span></h5>
                        <p className='card-description-text'>{shortDescription}</p>
                        {/*<div className="text-gray-700 small provider">
                            <span className="sr-only">Sponsors: </span><span width="220"><span><span>Sponsors</span></span></span>
                        </div>*/}
                    </div>
                <div className="d-card-footer x-small ml-4 x-small d-flex flex-column mb-4">
                    {type === 1
                    ? 'Lesson'
                    : 'Other'
                    }
                </div></div>
            </a>
        </div>


                {/* PREV BUILD
                    <div className="col-xs-12 col-sm-6 col-lg-4 card-holder meted-item-card">
                        <a className="card" href="/">
                        <h5 className="title">Satellite-NWP Integration: Weather System Analysis Challenge</h5>
                        <p>This 15-minute lesson uses GOES-R RGB composites and single channel imagery in combination with numerical weather prediction (NWP) model output to analyze synoptic scale weather systems. In this lesson, the learner is challenged to use the Airmass RGB and potential vor...
                        </p>
                        </a>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-lg-4 card-holder meted-item-card">
                        <a className="card" href="/">
                        <h5 className="title">Meteorological Analysis and Forecast Tools for Aviation</h5>
                        <p>This lesson provides learners with the opportunity to familiarize themselves with several essential analysis and forecast tools required to perform effectively in their aviation weather roles. This lesson is meant to provide both foundational knowledge about a select li...
                        </p>
                        </a>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-lg-4 card-holder">
                        <div className='meted-item-card'>
                        <a className="card" href="/">
                        <h5 className="title">What is the Hydrologic Ensemble Forecast Service (HEFS)?</h5>
                        <p>The Hydrologic Ensemble Forecast Service (HEFS) is an ensemble streamflow forecasting service provided by the National Weather Service (NWS). This training comprises three videos that explain what the HEFS is and how it works. The first of these videos introduces the pu...
                        </p>
                        </a>
                        </div>
                    </div>
                    */}

        </div>
    );
}

export default ResourceCard;