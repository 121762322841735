import React, { Fragment } from 'react';

function PromoBanner(props) {
    const {member} = props;
    return (
        <section className='hero row intro-hero'>
            <div id='promo-01' className="container justify-content-left">
                <div className="col-xs-12 text-left">
                    <h1>Build Expertise.<br/>Together.</h1>
                    <p><strong>MetEd</strong> is a catalog of customized training and technology solutions for the Earth sciences enterprise.<br/>Every lesson on this platform is free for all users – registration is quick and easy!</p>
                    
                    {!member
                    ? <Fragment>
                      <a className="btn btn-success" href="https://www.meted.ucar.edu/registration.php" target="_blank" rel='noreferrer'>Join for Free</a>
                      <hr/>
                      <a className="btn small" href="https://www.meted.ucar.edu" target="_blank" rel='noreferrer'><span className='glyphicon glyphicon-new-window'></span> Learn more about COMET and MetEd</a>
                      </Fragment>
                    : null
                    }
                    
                    
                </div>
            </div>
        </section>
    );
}

export default PromoBanner;