import React, { Component, Fragment } from 'react';
import Block from '../containers/Block';
//import ListItem from './ListItem';

class List extends Component {
    render() {
        const {content, glyph, level} = this.props.data;

        return (
            <>
            {glyph === "NUMBER"
                ?
                <ol data-level={level} type='1' >
                    {content.map((item, OLindex) => 
                        <Block key={`oli${OLindex}`} data={item} type='OL-Number' />
                    )}
                </ol>
                : null
            }
            {glyph === "LATIN_LOWER"
                ?
                <ol data-level={level} type='a' >
                    {content.map((item, OLindex) => 
                        <Block key={`oli${OLindex}`} data={item} type='OL-LatinLower' />
                    )}
                </ol>
                : null
            }
            {glyph === "ROMAN_LOWER"
                ?
                <ol data-level={level} type='i' >
                    {content.map((item, OLindex) => 
                        <Block key={`oli${OLindex}`} data={item} type='OL-RomanLower' />
                    )}
                </ol>
                : null
            }
            {glyph === "BULLET"
                ? 
                <ul data-level={level} type='disc'>
                    {content.map((item, ULindex) => 
                    <Block key={`uli${ULindex}`} data={item} type='UL-none' />
                    )}
                </ul>
                : null
            }
            {glyph === "HOLLOW_BULLET"
                ? 
                <ul data-level={level} type='circle'>
                    {content.map((item, ULindex) => 
                    <li key={ULindex}>
                        {item.type}: ITEM_CONTENT_MISSING!                   
                    </li>
                    )}
                </ul>
                : null
            }
            {glyph === "SQUARE_BULLET"
                ? 
                <ul data-level={level} type='square'>
                    {content.map((item, ULindex) => 
                    <li key={ULindex}>
                        {item.type}: ITEM_CONTENT_MISSING!                   
                    </li>
                    )}
                </ul>
                : null
            }
            </>
        )
    }
}

export default List;