import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';


const messages = defineMessages({

	all_topics: {
		id: 'all_topics',
		defaultMessage: 'All Topic Areas'
	}
})


class FilterTopics extends Component {
/*
    constructor(props) {
		super(props);
		
    }
*/
    selectTopic = (e) => {
        this.props.selectTopic(e.target.value);
	}
		

    render() {
       
        const { locale, formatMessage } = this.props.intl;

        let topicRowsObject = this.props.topics.map(element => {
            let transtitle = element.translations['en'].title;
            if (element.translations.hasOwnProperty(locale)) {
                transtitle = element.translations[locale].title;
            }

            return { meta_id: element.meta_id, transtitle: transtitle }
        });

        topicRowsObject.sort(function (a, b) {
            if (a.transtitle < b.transtitle) { return -1; }
            if (a.transtitle > b.transtitle) { return 1; }
            return 0;
        })

        //Add the "All Topics" at the top
        topicRowsObject = [{ "meta_id": 0, "transtitle": formatMessage(messages.all_topics) }, ...topicRowsObject];



        let topicRows = topicRowsObject.map(element => {
            return <option key={element.meta_id} value={element.meta_id}>{element.transtitle}</option>
        });

        return (
            <select value={this.props.value} onChange={this.selectTopic} >
                {topicRows}
             </select>
        );
    }
}

export default injectIntl(FilterTopics);

