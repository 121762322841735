import React, { Component } from 'react';
import Block from '../containers/Block';

class ReorderQuestion extends Component {
    render() {
        const {blockIndex, pageID, unit} = this.props;
        const {answers, /*correct,*/ feedback, question} = this.props.data;
        //const alphabet = 'abcdefghijklmnopqrstuvwxyz';
        
        /* QUESTION ID CONSTRUCTOR */
        let pageNumber = '';
        if (pageID)
        {
            pageNumber = pageID.replace(/-/g, '');
        }
        else
        {
            pageNumber = '--';
        }
        const elementID = `${unit}${pageNumber}${blockIndex}`;

        return (
            <div id={`q${elementID}`} className='panel panel-default interaction-question'>
                <div className='panel-body'>
                    <div className='question-wrap reordering'>
                        <h4>Question</h4>
                        {question.map((contentBlock, index) =>
                            <Block key={index} data={contentBlock} />)
                        }
                        <form action="#">
                        <fieldset>
                            <div className='response-group-item'>
                                <ul className='sortable sortIt ui-sortable'>
                                    {answers.map((answer, ansIndex) =>
                                        <li key={ansIndex} className='ui-state-default ui-sortable-handle'><span className='glyphicon glyphicon-th'></span>{answer}</li>
                                    )}
                                </ul>
                            </div>
                            <input className='submit-button btn btn-primary' name='submit-button' value='Done' type='button' />
                        </fieldset>
                        </form>
                        <div className='answer'>
                        {feedback.map((contentBlock, index) =>
                            <Block key={index} data={contentBlock} />)
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReorderQuestion;