import React, { Component, Fragment } from 'react';
import Block from '../containers/Block';

class Tabs extends Component {
    render() {
        const {tabs} = this.props.data;
        const {unit, pageID, blockIndex} = this.props;
        let pageNumber = '';

        /* ELEMENT ID CONSTRUCTOR */
        if (pageID)
        {
            pageNumber = pageID.replace(/-/g, '');
        }
        else
        {
            pageNumber = '--';
        } 
        const elementID = `${unit}${pageNumber}${blockIndex}`;

        return (
            <div id={`tabs-${elementID}`} className='tabs-container'>
                <ul id={`tab-set-${elementID}`} className="nav nav-tabs" role="tablist">
                    {tabs.map((tab, tabIndex) => 
                        <li key={`tab${tabIndex}`} className={tabIndex === 0 ? 'active' : null}><a href={`#tab-${elementID}-${tabIndex}`} data-toggle="tab" className="tab-switch">{tab.title}</a></li>
                    )}
                </ul>

                <div className='tab-content'>

                    {tabs.map((tab, tabIndex) =>
                        <Fragment key={tabIndex}>
                        <h3>{tab.title}</h3>
                        <div className={`tab-pane fade in ${tabIndex === 0 ? 'active' : null}`} id={`tab-${elementID}-${tabIndex}`}>
                            {tab.content.map((contentBlock, index) =>
                                <Block key={index} data={contentBlock} />)
                            }
                        </div>
                        </Fragment>
                    )}

                </div>
            </div>
        );
    }
}

export default Tabs;