import React from 'react';
import { Link } from 'react-router-dom';

function LessonBreadcrumbs(props) {
    const {activeUnit, units, data} = props;
    return (
        <div className="row hidden-sm hidden-xs">
            <nav aria-label="breadcrumb">
            {units !== null
                ?
                <ol className="breadcrumb">
                    <li><a href="."><i className="glyphicon glyphicon-home small"></i></a></li>
                    <li><a href="."><strong>{data.title}</strong></a></li>
                    {activeUnit !== null
                    ? <li><Link to='.'>{units[parseInt(activeUnit) - 1].title}</Link></li>
                    : null}
                </ol>
                :
                <ol className='breadcrumb'>
                    <li><a href="."><i className="glyphicon glyphicon-home small"></i></a></li>
                </ol>
            }
            </nav>
        </div>
    );
}

export default LessonBreadcrumbs;