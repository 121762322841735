//REDUCER for catalog
import { RECIEVE_SEARCH, SET_CATALOG_INITIALIZED, SET_QUERY, SET_PRUNE_TOPIC, SET_PRUNE_LANGUAGE, SET_SORT} from '../actions'

import { createAction, createReducer } from '@reduxjs/toolkit'

const initialCatalogState = {
    searchModules: [],
    searchCourses: [],
    searchUCourses: [],
    modules: [],
    courses: [],
    ucourses: [],
    sortOptionSelected: 0,
    sortOptionCoursesSelected: 0,
    pruneModules: [],
    pruneCourses: [],
    pruneUCourses: [],
    pruneTopic: 0,
    pruneLanguage: 1,
    query: "",
    topics: [],
    languages: [],
    ready: false
};


const catalog = createReducer(initialCatalogState, (builder) => {
  builder
    .addCase(createAction(SET_CATALOG_INITIALIZED), (state, action) => {
        state.ready = action.bool;
    })
    .addCase(createAction(RECIEVE_SEARCH), (state, action) => {
        state.topics = action.json.topics;
        state.languages = action.json.languages;

        state.modules = action.json.modules;

        state.pruneModules = prune(state,  action.json.modules);
        state.searchModules = searchQuery("", state.pruneModules)
        
    })
    .addCase(createAction(SET_QUERY), (state, action) => {
        state.query = action.query;
        state.searchModules = searchQuery(action.query, state.pruneModules);
        
    })
    .addCase(createAction(SET_PRUNE_TOPIC), (state, action) => {
        state.pruneTopic = parseInt(action.meta_id, 10);
        state.pruneModules = prune(state, state.modules);
        state.searchModules = searchQuery(state.query, state.pruneModules);
        
    })
    .addCase(createAction(SET_PRUNE_LANGUAGE), (state, action) => {
        state.pruneLanguage = parseInt(action.id, 10);
        state.pruneModules = prune(state, state.modules);
        state.searchModules = searchQuery(state.query, state.pruneModules);

    })
    .addCase(createAction(SET_SORT), (state, action) => {
        state.sortOptionSelected = parseInt(action.sort,10); 
        state.searchModules = searchQuery(state.query, state.pruneModules).sort(getLessonSortFunction(state.sortOptionSelected));
        
    })
   
})


/*
function catalog (state=initialCatalogState, action) {

    //let s = {...state};
    let {bool, json} = action;

	switch (action.type) {
        case SET_CATALOG_INITIALIZED:
            return {...state, ready: bool };
        
        case RECIEVE_SEARCH:
            return produce(state, draftState => {
                draftState.modules = json.modules;
                draftState.searchModules = searchQuery("", json.modules);
                draftState.topics = json.topics;
                draftState.languages = json.languages;
            })

        case SET_QUERY:

           // let cleanModules = produce()

            return produce(state, draftState => {
                draftState.query = action.query;
                draftState.searchModules = searchQuery(action.query, draftState.modules);
            })

        case SET_PRUNE_TOPIC:

            //s.pruneModules = prune(s, s.modules);
            //s.searchModules = searchQuery(state.query, s.pruneModules).sort(s.sortFunction);

            return produce(state, draftState => {
                draftState.pruneTopic = parseInt(action.meta_id, 10);
                draftState.pruneModules = prune(state, state.modules);
                draftState.searchModules = searchQuery(state.query, draftState.pruneModules)
            })

        default:
            return {...state};
    }
}
*/

export function getLessonSortFunction(n)
{
    switch(n ) {
        case 0:
            return sortByPublishDateDESC;
          break;
        case 1:
            return sortByPublishDateASC;
          break;
        case 2:
            //TODO Filter out all lessons that don't have an updated date
            return sortByUpdatedDateDESC;
          break;
        case 3:
            return sortByUpdatedDateASC;
          break;
        case 4:
            return sortBySkillDESC;
          break;
        case 5:
            return sortBySkillASC;
          break;

        default:
            return sortByPublishDateDESC;
    }
}

export function sortByPublishDateASC(a, b)
{
    let a_mill = Date.parse(a.json.publish_date);
    let b_mill = Date.parse(b.json.publish_date);

    //Always sort improperly set dates to the bottom. 
    if(isNaN(a_mill) && isNaN(b_mill))
    {
        return 0;
    }else if(isNaN(a_mill))
    {
        return 1;
    }else if(isNaN(b_mill))
    {
        return -1;
    }

    return a_mill - b_mill;
}

export function sortByPublishDateDESC(a, b)
{
    let a_mill = Date.parse(a.json.publish_date);
    let b_mill = Date.parse(b.json.publish_date);

    //Always sort improperly set dates to the bottom. 
    if(isNaN(a_mill) && isNaN(b_mill))
    {
        return 0;
    }else if(isNaN(a_mill))
    {
        return 1;
    }else if(isNaN(b_mill))
    {
        return -1;
    }

    return b_mill - a_mill;
}

export function sortByUpdatedDateASC(a, b)
{
    let a_mill = Date.parse(a.json.last_update);
    let b_mill = Date.parse(b.json.last_update);

    //Always sort improperly set dates to the bottom. 
    if(isNaN(a_mill) && isNaN(b_mill))
    {
        return 0;
    }else if(isNaN(a_mill))
    {
        return 1;
    }else if(isNaN(b_mill))
    {
        return -1;
    }

    return a_mill - b_mill;
}

export function sortByUpdatedDateDESC(a, b)
{
    let a_mill = Date.parse(a.json.last_update);
    let b_mill = Date.parse(b.json.last_update);

    //Always sort improperly set dates to the bottom. 
    if(isNaN(a_mill) && isNaN(b_mill))
    {
        return 0;
    }else if(isNaN(a_mill))
    {
        return 1;
    }else if(isNaN(b_mill))
    {
        return -1;
    }

    return b_mill - a_mill;
}

export function sortBySkillASC(a, b)
{
    return b.json.content_level - a.json.content_level;
}

export function sortBySkillDESC(a, b)
{
    return a.json.content_level - b.json.content_level;
}

export function sortByTitleDESC(a, b)
{
   
    if(a.json.title < b.json.title) { return -1; }
    if(a.json.title > b.json.title) { return 1; }
    return 0;
}

export function sortByTitleASC(a, b)
{
    if(a.json.title < b.json.title) { return 1; }
    if(a.json.title > b.json.title) { return -1; }
    return 0;
}

function prune(state, modules)
{
    let pl = pruneLangauge(state.pruneLanguage, modules);
    return pruneTopic(state.pruneTopic, pl);

}


function pruneTopic(pruneTopic, modules)
{
    if(pruneTopic === 0)
    {
        return modules;
    }else
    {
        return modules.filter(item => { 

            return item.json.topic_meta_ids.includes(pruneTopic)
        });
    }
    
}

function pruneLangauge(pruneLangauge, modules)
{
    if( pruneLangauge === 0)
    {
        return modules;
    }else
    {
        return modules.filter(item => { 

            if(parseInt(item.json.language_id, 10) === pruneLangauge )
            {
                return true;
            }
            return false;
        });
    }
}


function searchQuery(query, modules)
{
    return modules.filter(item => { 
        
        delete item.json.titleFind;

        let haystack = item.json.title.toLowerCase();
        let needle = query.toLowerCase()
        if( haystack.includes(needle) )
        {
            let index = item.json.title.toLowerCase().indexOf(query.toLowerCase());
            if (index >= 0) 
            { 
                let title = item.json.title;
                item.json.titleFind = title.substring(0,index) + "<span style='background-color: orange;'>" + title.substring(index,index + query.length) + "</span>" + title.substring(index + query.length);
            }
            
            
            return true;
        }
        return false;
    });
}


export default catalog;