import React, { Component, Fragment } from 'react';
//import Block from '../containers/Block';
//import List from './List';

class ListItem extends Component {
    render() {
        const {data} = this.props;
        const {content} = data;

        /* ORIGINAL MAPPING B/W CONTENT AND DATA
        Array.isArray(content)
            ? content.map((item, index) => 
                <li key={index}>
                    {JSON.stringify(item)}
                </li>
            )
            : data.map((item, index) =>
                <List key={index} data={item} />
            )     
        */

        return (
            <>
               {content.map((item, index) => 
                <li key={index}>
                    {item.text}
                </li>)
                } 
            </>
        );
    }
}



export default ListItem;