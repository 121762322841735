var es_ES = require("./es.json");
var zh_TW = require("./zh.json");
var pt_BR = require("./pt.json");
var fr_FR = require("./fr.json");
var ru_RU = require("./ru.json");

exports.es_ES = es_ES;
exports.zh_TW = zh_TW;
exports.pt_BR = pt_BR;
exports.fr_FR = fr_FR;
exports.ru_RU = ru_RU;
