import React, { Fragment } from 'react';
import CheckboxQuestion from '../blocks/CheckboxQuestion';
import DragDropQuestion from '../blocks/DragDropQuestion';
import DrawQuestion from '../blocks/DrawQuestion';
import Heading from '../blocks/Heading';
import List from '../blocks/List';
import ListItem from '../blocks/ListItem';
import MediaItem from '../blocks/MediaItem';
import Panel from '../blocks/Panel';
import Paragraph from '../blocks/Paragraph';
import RadioQuestion from '../blocks/RadioQuestion';
import ReorderQuestion from '../blocks/ReorderQuestion';
import SelectQuestion from '../blocks/SelectQuestion';
import Sweeper from '../blocks/Sweeper';
import Table from '../blocks/Table';
import Tabs from '../blocks/Tabs';
import TextEntryQuestion from '../blocks/TextEntryQuestion';

/* String-type block */
function blockSwitch(block, page_id, unit, index) {
    switch(block.type) {
        case 'heading':
            return <Heading data={block.content} pageID={page_id} unit={unit} blockIndex={index} />;
        case 'paragraph':
            return <Paragraph data={block.content} />;
        case 'text':
            return <Fragment>{block.text}</Fragment>;
        case 'bold':
            return <strong>{block.text}</strong>;
        case 'panel':
            return <Panel data={block} pageID={page_id} unit={unit} blockIndex={index} collapsible={false} />;
        case 'collapse_panel':
            return <Panel data={block} pageID={page_id} unit={unit} blockIndex={index} collapsible={true} />;
        case 'table':
            return <Table data={block.content} pageID={page_id} unit={unit} blockIndex={index} />;
        case 'tab':
            return <Tabs data={block} pageID={page_id} unit={unit} blockIndex={index} />;
        case 'sweeper':
            return <Sweeper id={`sweep-${page_id}${index}`} pageID={page_id} unit={unit} before={block.content.before} after={block.content.after} />;
        case 'listSet':
            return <List data={block} />;
        case 'listItem':
            return <ListItem data={block.content} />;
        case 'mediaItem':
            return <MediaItem data={block} pageID={page_id} unit={unit} blockIndex={index} />;
        case 'radioQuestion':
            return <RadioQuestion data={block} pageID={page_id} unit={unit} blockIndex={index} />;
        case 'checkboxQuestion':
            return <CheckboxQuestion data={block} pageID={page_id} unit={unit} blockIndex={index} />;
        case 'selectQuestion':
            return <SelectQuestion data={block} pageID={page_id} unit={unit} blockIndex={index} />;
        case 'textQuestion':
            return <TextEntryQuestion data={block} pageID={page_id} unit={unit} blockIndex={index} />;
        case 'drawQuestion':
            return <DrawQuestion data={block} pageID={page_id} unit={unit} blockIndex={index} />;
        case 'dragQuestion':
            return <DragDropQuestion data={block} pageID={page_id} unit={unit} blockIndex={index} />;
        case 'orderingQuestion':
            return <ReorderQuestion data={block} pageID={page_id} unit={unit} blockIndex={index} />;
        default:
            return <div className='dev-block'>[dev::BLOCK::] {block.type}</div>;
      }
}

/* Array-type block */
function subBlockSwitch(block, index) {
    switch(block.type[0]) {
        case 'link':
            return <a key={`sub${index}`} href={block.link} target="_blank" rel='noreferrer'>{block.text}</a>;
        case 'highlight':
            return <span key={`sub${index}`} style={{backgroundColor: block.color}}>{block.text}</span>;
        case 'bold':
            return <strong key={`sub${index}`}>{block.text}</strong>
        default:
            return <div key={`sub${index}`} className='dev-block'>[dev::SUB_BLOCK::] {block.type}</div>;
    }
}

function Block(props) {
    if (typeof props.data.type === 'string')
    {
        return (
        <>
           {blockSwitch(props.data, props.pageID, props.unit, props.blockIndex)}
        </>
        );
    }
    else {
        return (
        <>
            {subBlockSwitch(props.data, props.blockIndex)}    
        </>
        )
    }
    
}

export default Block;