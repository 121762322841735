//ACTIONS
import fetch from 'isomorphic-fetch';

export const SET_QUERY = 'SET_QUERY';
export const RECEIVE_USER_STATUS = 'RECEIVE_USER_STATUS';
export const RECIEVE_USER_QUIZ = 'RECIEVE_USER_QUIZ';
export const RECIEVE_USER_SURVEY = 'RECIEVE_USER_SURVEY';
export const RECIEVE_USER_FEEDBACK = 'RECIEVE_USER_FEEDBACK';
export const RECIEVE_USER_METADATA = 'RECIEVE_USER_METADATA';
export const RECIEVE_USER_COMPLETIONS = 'RECIEVE_USER_COMPLETIONS';

export const SET_PRUNE_TOPIC = 'SET_PRUNE_TOPIC';
export const SET_PRUNE_LANGUAGE = 'SET_PRUNE_LANGUAGE';
export const SET_SORT = 'SET_SORT';

export const RECEIVE_MODULE_DATA = 'RECEIVE_MODULE_DATA';
export const RECIEVE_MODULE = 'RECIEVE_MODULE';

export const RECIEVE_COURSE = 'RECIEVE_COURSE';

export const RECIEVE_SEARCH = 'RECIEVE_SEARCH';

//export const SET_SERVER_OK = 'SET_SERVER_OK';
export const SHOW_ERROR_PAGE = 'SHOW_ERROR_PAGE';
//export const SHOW_SCHEMA_ERROR = 'SHOW_SCHEMA_ERROR';

export const SET_USER_INITIALIZED = 'SET_USER_INITIALIZED';
export const SET_CATALOG_INITIALIZED = 'SET_CATALOG_INITIALIZED';
export const SET_LESSON_INITIALIZED = 'SET_LESSON_INITIALIZED';
export const SET_COURSE_INITIALIZED = 'SET_COURSE_INITIALIZED';

//Viewer Reducer
export const RECIEVE_VIEWER = 'RECIEVE_VIEWER';

export const SET_LOCALE = 'SET_LOCALE';

export const SET_DEVELOPER_TOOLS = 'SET_DEVELOPER_TOOLS';
export const SET_DEVMODE = 'SET_DEVMODE';

//Handle errors
function handleErrors(response) {
	if (!response.ok) {
		let e = Error(response.statusText + " " + response.status);
		e.name = "ResponseError";
		throw e;
	}
	return response;
}

export function setQuery(query) {
	return dispatch => {
		dispatch({ type: SET_QUERY, query: query });
	}
}

export function setLocale(locale) {
	return dispatch => {

		//First update the ui so there is no UI lag.
		dispatch({ type: SET_LOCALE, locale: locale });
        /*
		//Then async store the data, if there is an error let the user know.  
		return fetch(`/education_training/data_v2.php?get=setLocale&locale=${locale}`, { credentials: 'include' })
			.then(response => response.json())
			.catch(error => console.log('Perhaps unable to store user language preference:', error))
			.then(function (json) {
				dispatch({ type: SET_SERVER_OK, json: json });
			})
            */
	}
}

//Init check
export function initAccess() {
    return dispatch => {
        return fetch(`/education_training/data_v2.php?get=init`)
            .then(response => response.json())
            .then(function (json) {
                if (json.status === 'success') {
                    dispatch({ type: RECEIVE_USER_STATUS, user: json.user.metadata });
                }
                else {
                    dispatch({ type: RECEIVE_USER_STATUS, user: {'logged_in': false} })
                    console.log('Rdx: initAccess() --> user', json.error);
                }
            })
    }
}

//Get catalog data
export function getCatalog() {
	return dispatch => {
		return fetch(`/api/catalog`, { credentials: 'include' })
			.then(handleErrors)
			.then(response => response.json())
			.then(function (json) {
				dispatch({ type: RECIEVE_SEARCH, json: json });
				//dispatch({ type: RECIEVE_USER_METADATA, userMetadata: json.userMetadata });
				//dispatch({ type: RECIEVE_USER_COMPLETIONS, userCompletions: json.userCompletions });
				//dispatch({ type: SET_USER_INITIALIZED, bool: true });
				dispatch({ type: SET_CATALOG_INITIALIZED, bool: true });
			})
			.catch(error => {
				dispatch({ type: SHOW_ERROR_PAGE, error: error });
                dispatch({ type: SET_CATALOG_INITIALIZED, bool: false});
                console.log('error catch for catatalog set');
			});

	}
}

export function setTopicPrune(meta_id) {
	return dispatch => {
		dispatch({ type: SET_PRUNE_TOPIC, meta_id: meta_id });
	}
}

export function setLanguagePrune(id) {
	return dispatch => {
		dispatch({ type: SET_PRUNE_LANGUAGE, id: id });
	}
}

export function setSort(sort) {
	return dispatch => {
		dispatch({ type: SET_SORT, sort: sort });
	}
}


//Get the lesson/module data
export function getModuleDataFor(lesson_id) {
    return dispatch => {
        return fetch(`/api/catalog/gdocsLesson/${lesson_id}`)
            .then(response => response.json())
            .then(function (json) {
                if (json.status === 'success') {
                    dispatch({ type: RECEIVE_MODULE_DATA, module: json.module });
                }
                else {
                    console.log('Action Failed: Get module/lesson data for [Lesson ID]. ', json.error);
                }
            })
    }
}


//Developer tools
export function setDeveloperTools(toggles) {
    return {
        type: SET_DEVELOPER_TOOLS,
        toggles
    }
}
export function setDevMode(status) {
    return {
        type: SET_DEVMODE,
        status
    }
}