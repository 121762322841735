import React from 'react';
import BarLoader from 'react-spinners/BarLoader';


function LoadingPadded(props) {
    return (
        <div className='padded-loader center-block'>
            Loading...
            <BarLoader color='#bbb' />
        </div>
    );
}

export default LoadingPadded;